import React from 'react';
import { Link } from 'react-router-dom';

import NavigationMenu from './NavigationMenu';
import SocialLink from './SocialLink';
import Footer from '../Footer/Footer';

import HeaderLogo from '../../resources/logo.svg';

import './Styles/SiteHead.scss';
import './Styles/SiteHead-mobile.scss';
import './Styles/SiteHead-tablet.scss';

const SiteHead = () => {
  const sidebarRef = React.createRef();

  const toggleMenu = () => {
    sidebarRef.current.classList.toggle('open_nav');
  };

  return (
    <header ref={sidebarRef}>
      <div className='header-inner-container'>
        <div className='header-top'>
          <Link id='site-logo-wrapper' to='/'>
            <img id='site-logo' src={HeaderLogo} alt='Website logo' />
          </Link>
          <h1>BARDICIOUS</h1>
          <button className='nav-display-button' onClick={toggleMenu}>
            <i className='fas fa-bars' />
          </button>
        </div>
        <div className='header-bottom'>
          <NavigationMenu onClick={toggleMenu} />
          <div id='social-network-links'>
            <SocialLink socialNetwork='cara' username='bardicious' />
            <SocialLink socialNetwork='artstation' username='bardicious' />
            <SocialLink
              socialNetwork='email'
              username='nastiazuchko@gmail.com'
            />
          </div>
          <Footer />
        </div>
        <div className='grayness' onClick={toggleMenu} />
      </div>
    </header>
  );
};

export default SiteHead;
