import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as TwitterIcon } from './../../resources/icons/twitter.svg';
import { ReactComponent as TumblrIcon } from './../../resources/icons/tumblr.svg';
import { ReactComponent as InstagramIcon } from './../../resources/icons/instagram.svg';
import { ReactComponent as CaraIcon } from './../../resources/icons/cara.svg';
import { ReactComponent as ArtstationIcon } from './../../resources/icons/artstation.svg';
import { ReactComponent as EmailIcon } from './../../resources/icons/email.svg';

const SocialLink = ({ username, socialNetwork }) => {
  let socialNetworkUrl = '';
  let svg;

  switch (socialNetwork) {
    case 'twitter':
      socialNetworkUrl += 'https://twitter.com/{x}';
      svg = <TwitterIcon className='twitter-icon' />;
      break;
    case 'tumblr':
      socialNetworkUrl += 'https://{x}.tumblr.com';
      svg = <TumblrIcon className='tumblr-icon' />;
      break;
    case 'instagram':
      socialNetworkUrl += 'https://instagram.com/{x}';
      svg = <InstagramIcon className='instagram-icon' />;
      break;
    case 'email':
      socialNetworkUrl += 'mailto:{x}';
      svg = <EmailIcon className='email-icon' />;
      break;
    case 'cara':
      socialNetworkUrl += 'https://www.cara.app/{x}';
      svg = <CaraIcon className='cara-icon' />;
      break;
    case 'artstation':
      socialNetworkUrl += 'https://www.artstation.com/{x}';
      svg = <ArtstationIcon className='artstation-icon' />;
      break;
    default:
      break;
  }

  socialNetworkUrl = socialNetworkUrl.replace('{x}', username);

  return (
    <a
      href={socialNetworkUrl}
      className='social-network-link'
      target='_blank'
      rel='noopener noreferrer'
    >
      {svg} {socialNetwork}
    </a>
  );
};

SocialLink.propTypes = {
  socialNetwork: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default SocialLink;
